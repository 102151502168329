<script setup lang="ts">
  import { TransactionMethod, TransactionMethodList, UserVerificationStatus } from '@/interfaces';

  const localePath = useLocalePath();
  const { t } = useI18n();

  const modal = useModalStore();
  const userStore = useUserStore();
  const isApproved = computed(
    () => userStore.getVerificationStatus === UserVerificationStatus.APPROVED,
  );

  const openMethod = (method: TransactionMethod) => {
    if (!isApproved.value) return;
    modal.create({ id: `balance-${method.toLowerCase()}`, data: { method } });
  };
</script>

<template>
  <section class="container">
    <div class="container-header">{{ t('components.common.header.topUpBalance') }}:</div>
    <div class="separator"></div>
    <div class="container-main flex-col"> 
      <div
        class="method"
        v-for="item of TransactionMethodList" 
        :key="item._id"
        @click="openMethod(item._id)"
      >
        {{ t(`components.common.header.${item._id}`) }} 
      </div>
    </div>
    <!-- <div class="separator"></div>
    <nuxt-link class="container-footer flex flex-ic cursor-pointer" :to="localePath({ name: Page.ORDER_MYSELF })">
      <common-icon-common-transfer></common-icon-common-transfer>
      <span>{{ t('components.common.header.betweenYourAccounts') }}</span>
    </nuxt-link> -->
  </section>
</template>

<style scoped lang="scss">
.container {
  @apply w-[276px] rounded-md border border-solid border-accent p-2 bg-white text-sm;
  &-header {
    @apply font-medium py-1.5 px-2;
  }
  &-main {
    .method {
      transition: 0.3s all;
      @apply w-full py-1.5 px-2 rounded-md cursor-pointer;
      &:hover {
        transition: 0.3s all;
        @apply bg-gray-5;
      }
    }
  }
  &-footer {
    @apply w-full py-1.5 px-2 gap-2 rounded-md;
    &:hover {
      transition: 0.3s all;
      @apply bg-gray-5;
    }
  }
  .separator {
    @apply w-full h-[1px] bg-gray-4 my-1;
  }
}
</style>
